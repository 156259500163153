import api from './api';
import {
  Advertisement,
  AdvertisementParams,
  AdvertisementResponse,
  Category,
  Location,
  PostReview,
  ReviewResponse,
} from './types';
import { sendAmplitudeEvent } from '../utilities/amplitude';
import { WEB_APP } from '../common/telegram';

export const getAdvertisements = (
  params: AdvertisementParams,
  pageParam = 1
): Promise<AdvertisementResponse> => {
  sendAmplitudeEvent('Get advs', { ...params });

  return api
    .get('search/advs/', { params: { ...params, page: pageParam }, paramsSerializer: { indexes: null } })
    .then(({ data }) => data)
    .catch(e => console.log('error', e));
};

export const getAdvertisementById = (id?: string): Promise<Advertisement> => {
  if (!id) return Promise.reject('No id provided');

  const config = {
    headers: {
      'TG-INIT-DATA': WEB_APP?.initData,
    },
  };

  return api
    .get(`search/adv/${id}`, config)
    .then(({ data }) => data)
    .catch(e => console.log('error', e));
};

export const getFilters = (): Promise<{ locations: Location[]; categories: Category[] }> =>
  api
    .get('search/filters/')
    .then(({ data }) => data)
    .catch(e => console.log('error', e));

export const getReviews = (id?: string, pageParam = 1): Promise<ReviewResponse> => {
  if (!id) return Promise.reject('No id provided');

  return api
    .get(`search/${id}/reviews`)
    .then(({ data }) => data)
    .catch(e => console.log('error', e));
};

export const postReview = (id?: string, review?: PostReview): Promise<{ id: number }> => {
  if (!id) return Promise.reject('No id provided');

  const config = {
    headers: {
      'TG-INIT-DATA': WEB_APP?.initData,
    },
  };

  return api
    .post(`search/adv/${id}/review`, review, config)
    .then(({ data }) => data)
    .catch(e => console.log('error', e));
};
