import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  amplitude.init(
    process.env.REACT_APP_AMPLITUDE ?? '',
    (Telegram.WebApp.initDataUnsafe.user?.id ?? '').toString(),
    {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
    }
  );
};

export const sendAmplitudeEvent = (eventType: string, eventProperties?: { [key: string]: unknown }) => {
  amplitude.track(eventType, eventProperties);
};
