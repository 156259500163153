import { Review } from '../../../../services/types';
import { Avatar, Rating, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { StyledButton } from '../../styles';
import { REVIEW_INFO_LENGTH } from '../../constants';

export interface ViewReviewCardProps {
  review: Review;
}
export const ViewReviewCard = ({ review: { rating, author, content, created_at } }: ViewReviewCardProps) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar src={author?.photo_thumbnail_url ?? ''} />
          <Typography variant="subtitle1" fontWeight={600} lineHeight={1.2}>
            {author?.name ?? ''}
          </Typography>
        </Stack>
        <Typography variant="body1" color="text.secondary" whiteSpace="nowrap">
          {dayjs(created_at).format('MMMM YYYY')}
        </Typography>
      </Stack>
      <div>
        <Rating size="medium" value={rating} readOnly />
        <Typography variant="body1">
          <span style={{ paddingRight: '4px' }}>
            {showMore || content.length <= REVIEW_INFO_LENGTH
              ? content
              : `${content.substring(0, REVIEW_INFO_LENGTH)}...`}
          </span>
          <span>
            {content.length > REVIEW_INFO_LENGTH && !showMore && (
              <StyledButton variant="text" onClick={() => setShowMore(true)} color="primary">
                Читать больше
              </StyledButton>
            )}
          </span>
        </Typography>
      </div>
    </Stack>
  );
};
