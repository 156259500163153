import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './styles';
import {
  StyledCloseButtonContainer,
  StyledDialog,
  StyledModalContent,
  StyledModalFooter,
  StyledModalHeader,
  StyledPrimaryActionContainer,
} from './styles';
import { LoadingButton } from '@mui/lab';

//TODO: Transition

export const Modal = ({
  open,
  onClose,
  title,
  children,
  footerContent,
  primaryAction,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  footerContent?: ReactNode;
  primaryAction?: { content: string; onAction: () => void; loading?: boolean; disabled?: boolean };
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const Transition = React.forwardRef<HTMLDivElement, SlideProps>((props, ref) => {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <StyledDialog
      // TransitionComponent={isMobile ? Transition : undefined}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          maxHeight: '93%',
          borderRadius: '26px 26px 0 0',
          marginTop: isMobile ? 0 : theme.spacing(2),
          marginBottom: isMobile ? 0 : theme.spacing(2),
          backgroundColor: theme.palette.background.main,
        },
      }}>
      <StyledModalHeader>
        <StyledCloseButtonContainer>
          <IconButton sx={{ padding: 0, color: 'text.secondary' }} onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </StyledCloseButtonContainer>
        <Typography component="div" fontWeight={600} lineHeight={'24px'} align="center">
          {title}
        </Typography>
        {primaryAction && (
          <StyledPrimaryActionContainer>
            <LoadingButton
              variant="contained"
              disabled={primaryAction?.disabled}
              loading={primaryAction?.loading}
              size="small"
              onClick={primaryAction.onAction}>
              {primaryAction.content}
            </LoadingButton>
          </StyledPrimaryActionContainer>
        )}
      </StyledModalHeader>
      <StyledModalContent>{children}</StyledModalContent>
      {footerContent && <StyledModalFooter>{footerContent}</StyledModalFooter>}
    </StyledDialog>
  );
};
