import React, { useMemo, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Box, Container, Divider, FormControlLabel, FormGroup, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuery } from 'react-query';
import { getFilters } from '../../../services/search';
import { DEFAULT_CATEGORY } from './constants';
import { Props } from './types';
import { Modal, Checkbox } from '../../../common/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledHeadingTypography, StyledChip } from './styles';

export const FiltersModal = ({
  open,
  onClose,
  category,
  locations,
  onCategoryChange,
  onLocationChange,
  count,
  isDataLoading,
}: Props) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const { isLoading, data } = useQuery('filters', () => getFilters());

  const sortedLocations = useMemo(() => {
    if (open) {
      return data?.locations?.sort((a, b) => {
        const aIndex = locations.indexOf(a.name);
        const bIndex = locations.indexOf(b.name);
        if (aIndex >= 0 && bIndex >= 0) {
          return a.name.localeCompare(b.name);
        } else if (aIndex >= 0) {
          return -1;
        } else if (bIndex >= 0) {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });
    }

    return data?.locations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data?.locations]);

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, checked },
    } = event;

    if (checked) {
      onLocationChange([...locations, name]);
    } else {
      onLocationChange(locations.filter(location => location !== name));
    }
  };

  const clearFilters = () => {
    onLocationChange(null);
    onCategoryChange(null);
  };

  const closeModal = () => {
    setShowMore(false);
    onClose();
  };

  const footerContent = (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <Link component="button" underline="none" variant="body1" color="text.primary" onClick={clearFilters}>
        Сбросить
      </Link>
      <LoadingButton variant="contained" size="small" onClick={closeModal} loading={isDataLoading}>
        {`Показать ${count} услуг${count % 10 === 1 ? 'у' : count % 10 > 1 && count % 10 < 5 ? 'и' : ''}`}
      </LoadingButton>
    </Stack>
  );

  return (
    <Modal open={open} onClose={closeModal} title="Фильтры" footerContent={footerContent}>
      <Container>
        <FormControl sx={{ display: 'flex', mt: 3 }} component="fieldset" variant="standard">
          <StyledHeadingTypography variant="h4">Город</StyledHeadingTypography>
          <FormGroup sx={{ mb: 2, gap: '12px' }}>
            {!isLoading &&
              sortedLocations
                ?.slice(0, !showMore ? 5 : undefined)
                ?.map(({ name }) => (
                  <FormControlLabel
                    key={name}
                    style={{ margin: 0, justifyContent: 'space-between' }}
                    control={
                      <Checkbox
                        style={{ padding: '4px' }}
                        checked={locations.indexOf(name) > -1}
                        onChange={handleLocationChange}
                        name={name}
                      />
                    }
                    label={name}
                    labelPlacement="start"
                  />
                ))}
          </FormGroup>
        </FormControl>
        <Link component="button" variant="body2" onClick={() => setShowMore(!showMore)} underline="none">
          <Stack direction="row" alignItems="center">
            <Typography
              fontSize={15}
              fontWeight={500}
              color="text.secondary"
              sx={{
                textDecoration: 'underline',
                textDecorationThickness: 1,
              }}>
              {showMore ? 'Свернуть' : 'Показать больше'}{' '}
            </Typography>
            <ExpandMoreIcon
              sx={{
                color: 'text.secondary',
                transition: 'transform 0.3s',
                transform: showMore ? 'rotate(180deg)' : 'none',
              }}
              fontSize="small"
            />
          </Stack>
        </Link>

        <Box marginY={3}>
          <Divider sx={{ borderColor: '#E6E6E6' }} />
        </Box>

        <div style={{ paddingBottom: '24px' }}>
          <StyledHeadingTypography variant="h4">Категория</StyledHeadingTypography>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
            <StyledChip
              variant={category === DEFAULT_CATEGORY.name ? 'filled' : 'outlined'}
              size="medium"
              onClick={() => onCategoryChange(null)}
              color={category === DEFAULT_CATEGORY.name ? 'primary' : 'default'}
              label={DEFAULT_CATEGORY.name}
              selected={category === DEFAULT_CATEGORY.name}
            />
            {!isLoading &&
              data?.categories?.map(({ name }) => (
                <StyledChip
                  variant={category === name ? 'filled' : 'outlined'}
                  onClick={() => onCategoryChange(name)}
                  key={name}
                  color={category === name ? 'primary' : 'default'}
                  label={name}
                  selected={category === name}
                />
              ))}
          </div>
        </div>
      </Container>
    </Modal>
  );
};
