import { Typography, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledHeadingTypography = styled(Typography)(() => ({
  fontSize: 19,
  lineHeight: '24px',
  fontWeight: '600',
  marginBottom: 16,
}));

interface StyledChipProps {
  selected: boolean;
}

export const StyledChip = styled(Chip)<StyledChipProps>(({ selected, theme: { palette } }) => ({
  height: '28px',
  fontWeight: '500',
  color: selected ? palette.primary.contrastText : palette.text.secondary,
  border: '1px solid',
  '&.MuiChip-filled': {
    border: '1px solid transparent',
    ' &:hover': {
      backgroundColor: palette.primary.main,
    },
  },
}));
