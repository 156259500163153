import { useEffect } from 'react';
import { MainButtonProps } from './types';
import { useTheme } from '@mui/material';
import { WEB_APP } from '../constants';

export const MainButton = ({
  text = 'CONTINUE',
  progress = false,
  disable = false,
  onClick,
}: MainButtonProps): null => {
  const theme = useTheme();

  useEffect(() => {
    WEB_APP?.MainButton?.setParams({
      color: theme.palette.primary.main || WEB_APP?.themeParams.button_color || '#fff',
      text_color: theme.palette.primary.contrastText || WEB_APP?.themeParams.button_text_color || '#000',
    });
    WEB_APP?.MainButton?.setText(text?.toUpperCase());
  }, [text, theme]);

  useEffect(() => {
    if (WEB_APP?.MainButton?.isActive && disable) {
      WEB_APP?.MainButton.disable();
    } else if (!WEB_APP?.MainButton?.isActive && !disable) {
      WEB_APP?.MainButton?.enable();
    }
  }, [disable]);

  useEffect(() => {
    if (!WEB_APP?.MainButton?.isProgressVisible && progress) {
      WEB_APP?.MainButton?.showProgress(false);
    } else if (WEB_APP?.MainButton?.isProgressVisible && !progress) {
      WEB_APP?.MainButton.hideProgress();
    }
  }, [progress]);

  useEffect(() => {
    if (!onClick) {
      return;
    }

    WEB_APP?.MainButton?.onClick(onClick);
    return () => {
      WEB_APP?.MainButton?.offClick(onClick);
    };
  }, [onClick]);

  useEffect(() => {
    WEB_APP?.MainButton?.show();
    return () => {
      WEB_APP?.MainButton?.hide();
    };
  }, []);

  return null;
};
