import { Stack, Typography } from '@mui/material';
import { Props } from './types';

export const IconWithDescription = ({
  IconComponent,
  iconFontSize = 16,
  description,
  color = 'text.secondary',
  iconColor = 'text.secondary',
  fontSize = 13,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <IconComponent sx={{ color: iconColor, fontSize: iconFontSize }} />
      <Typography variant="body2" color={color} fontSize={fontSize} lineHeight={2}>
        {description}
      </Typography>
    </Stack>
  );
};
