import { Avatar, Button, Rating, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import dayjs from 'dayjs';
import { Slider } from '../../../common/components/Slider';
import { WEB_APP } from '../../../common/telegram';
import { Props } from './types';
import { NewReviewModal } from './NewReviewModal';
import { StyledClampText, StyledSlideBottomLine, StyledSlideWrapper } from './styles';
import { ViewReviewsModal } from './ViewReviewsModal';

export const Reviews = ({ advertisement }: Props) => {
  const [newReviewModal, setNewReviewModal] = useState(false);
  const [viewReviewsModal, setViewReviewsModal] = useState(false);
  const [rating, setRating] = useState<number | null>(null);

  useEffect(() => {
    if (newReviewModal) {
      WEB_APP?.MainButton?.hide();
    } else {
      WEB_APP?.MainButton?.show();
    }
  }, [newReviewModal]);

  const handleOpenModal = (value: number | null) => {
    setRating(value);
    setNewReviewModal(true);
  };

  return (
    <>
      <div>
        <Typography variant="h4" fontSize={17} lineHeight="24px" fontWeight={600}>
          Отзывы
        </Typography>
        {!advertisement?.reviews?.length && (
          <Typography variant="body1" color="text.secondary" marginY={2}>
            Отзывов пока нет
          </Typography>
        )}
        {advertisement?.reviews?.length !== 0 && (
          <Slider>
            {advertisement?.reviews?.slice(0, 5)?.map(({ content, rating, created_at }, index) => {
              return (
                <Slider.Slide key={index}>
                  <StyledSlideWrapper>
                    <StyledClampText variant="body1" whiteSpace="pre-line" mb={1}>
                      {content}
                    </StyledClampText>
                    <StyledSlideBottomLine>
                      <StarIcon sx={{ color: '#FFB400', fontSize: 16, marginRight: 0.5 }} />
                      <Typography variant="caption" color="text.secondary" mr={1.5}>
                        {parseFloat(rating.toString()).toFixed(1)}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {dayjs(created_at).format('MMM, YYYY')}
                      </Typography>
                    </StyledSlideBottomLine>
                  </StyledSlideWrapper>
                </Slider.Slide>
              );
            })}
          </Slider>
        )}
        {!!advertisement?.reviews?.length && (
          <Button variant="outlined" color="inherit" fullWidth onClick={() => setViewReviewsModal(true)}>
            Читать все отзывы
          </Button>
        )}

        <Typography variant="h4" fontSize={15} lineHeight="24px" fontWeight={600} mt={2} mb={1}>
          Ваша оценка
        </Typography>
        {advertisement?.is_user_has_review ? (
          <Typography variant="body1" mb={2} color="text.secondary">
            Спасибо, что поделились своими впечатлениями. Чтобы отредактировать отзыв напишите нашему
            администратору.
          </Typography>
        ) : (
          <>
            <Typography variant="body1" mb={2} color="text.secondary">
              Оставьте свой отзыв, чтобы помочь другим пользователям
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar src={WEB_APP?.initDataUnsafe?.user?.photo_url} />
              <Rating
                size="large"
                value={rating}
                onChange={(_, newValue) => {
                  handleOpenModal(newValue);
                }}
              />
            </Stack>
          </>
        )}
      </div>
      <NewReviewModal
        rating={rating}
        onChangeRating={setRating}
        open={newReviewModal}
        onClose={() => {
          setRating(0);
          setNewReviewModal(false);
          // ratingRef?.current?.click();
        }}
      />
      <ViewReviewsModal
        open={viewReviewsModal}
        onClose={() => {
          setRating(0);
          setViewReviewsModal(false);
        }}
        rating={advertisement?.rating}
        reviewsCount={advertisement?.reviews_count}
      />
    </>
  );
};
