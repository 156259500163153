import React from 'react';
import { AdvertisementView } from '../components/AdvertisementView';
import { BackButton, IS_WEB_APP } from '../common/telegram';
import { useNavigate } from 'react-router-dom';

export const Advertisement = () => {
  const navigate = useNavigate();
  return (
    <>
      {IS_WEB_APP && <BackButton onClick={() => navigate(-1)} />}
      <AdvertisementView />
    </>
  );
};
