import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '6px 8px 6px 12px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.searchBar,
  '& *': {
    color: theme.palette.text.primary,
  },
})) as typeof Paper;
