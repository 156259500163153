import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function FilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M8.98248 0.1375C8.44927 0.255078 7.99263 0.501172 7.61529 0.873047C7.26255 1.21758 7.07115 1.51562 6.92076 1.95312L6.84966 2.1582L3.64771 2.17188C0.577006 2.18555 0.445756 2.18828 0.369194 2.2375C0.144975 2.37695 0.0328655 2.55195 0.00825616 2.78984C-0.0190876 3.06055 0.150444 3.34766 0.410209 3.46797C0.533256 3.52539 0.552397 3.52539 3.69419 3.53359L6.85513 3.53906L6.91802 3.725C7.05748 4.13789 7.30357 4.52344 7.62349 4.82969C7.97349 5.16328 8.36998 5.3875 8.83208 5.51328C9.03169 5.56797 9.1274 5.57617 9.52935 5.57617C9.9313 5.57617 10.027 5.56797 10.2266 5.51328C11.0251 5.29453 11.684 4.74219 12.0313 3.9957C12.0887 3.87266 12.1489 3.71953 12.1653 3.65664L12.1954 3.54453L12.8325 3.53359C13.5325 3.52266 13.6008 3.50898 13.7758 3.33945C13.929 3.19453 13.9836 3.06055 13.9836 2.8418C13.9836 2.57109 13.8852 2.39609 13.6309 2.2375C13.5598 2.19102 13.4751 2.18281 12.8817 2.17188L12.2118 2.1582L12.1133 1.89844C11.993 1.58125 11.9083 1.41992 11.7305 1.18477C11.3723 0.70625 10.8719 0.364453 10.2676 0.186719C10.0598 0.126563 9.95591 0.112891 9.59771 0.104688C9.28326 0.0964847 9.12466 0.104688 8.98248 0.1375ZM9.90123 1.50469C10.1473 1.57852 10.3114 1.67695 10.5028 1.86836C10.7844 2.15 10.9102 2.44805 10.9102 2.8418C10.9102 3.36406 10.6313 3.83164 10.1801 4.06406C9.9231 4.19805 9.75357 4.23086 9.43091 4.21719C9.17935 4.20352 9.14107 4.19531 8.93052 4.09141C8.43013 3.84258 8.14849 3.39414 8.14849 2.83906C8.14849 2.46172 8.27154 2.1582 8.53404 1.88203C8.80748 1.60039 9.12193 1.46367 9.51841 1.46367C9.65787 1.46094 9.81099 1.47734 9.90123 1.50469Z"
        fill="currentColor"
      />
      <path
        d="M3.99764 6.38549C3.59842 6.45385 3.1035 6.67533 2.77264 6.93236C2.35155 7.25775 1.98241 7.79096 1.84295 8.28041L1.79373 8.45541L1.16209 8.46635C0.448421 8.47729 0.371858 8.49369 0.188655 8.69877C-0.117595 9.03783 -0.0382978 9.50268 0.369124 9.76244C0.440218 9.80893 0.524983 9.81713 1.12928 9.82807L1.81014 9.84174L1.8703 10.0195C2.22576 11.0339 3.16912 11.7667 4.22459 11.846C5.05858 11.9089 5.78319 11.6574 6.37655 11.0913C6.68826 10.7933 6.92889 10.4214 7.07108 10.0195L7.13397 9.84174L10.3414 9.82807C13.423 9.81439 13.5543 9.81166 13.6308 9.76244C13.7648 9.68041 13.8633 9.5765 13.9262 9.45893C14.0082 9.3058 14.0082 9.01049 13.9262 8.85736C13.8523 8.71518 13.7156 8.58666 13.5762 8.52377C13.4722 8.47729 13.2808 8.47455 10.3031 8.46635C7.32537 8.46088 7.1367 8.45541 7.1367 8.41166C7.13397 8.31869 6.95076 7.88119 6.81951 7.66244C6.64998 7.3808 6.26444 6.98158 5.9828 6.79838C5.73944 6.64252 5.44686 6.514 5.14608 6.43197C4.90545 6.36908 4.24373 6.34174 3.99764 6.38549ZM4.82069 7.76361C5.06678 7.8265 5.24725 7.93314 5.44412 8.13002C5.72576 8.41166 5.85155 8.71244 5.85155 9.10072C5.85155 9.22377 5.83514 9.3851 5.81326 9.46166C5.56991 10.3749 4.47616 10.7824 3.69412 10.2546C3.1117 9.86361 2.9203 9.09252 3.24569 8.44721C3.33866 8.26947 3.60116 7.99604 3.77889 7.90033C4.09881 7.72533 4.48436 7.67611 4.82069 7.76361Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
