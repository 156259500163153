import React, { useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Divider,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { Home } from '@mui/icons-material';
import { Advertisement } from '../../services/types';
import dayjs from 'dayjs';
import {
  FloatingButton,
  IconWithDescription,
  ImageSlider,
  InstagramIcon,
  Modal,
  ScooterIcon,
} from '../../common/components';
import { sendAmplitudeEvent } from '../../utilities/amplitude';
import { getAdvertisementById } from '../../services/search';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { SERVICES_INFO_LENGTH, SLIDE_COUNT } from './constants';
import { Reviews } from './Reviews';
import StarIcon from '@mui/icons-material/Star';
import { getPluralReviews } from '../../utilities';
import { StyledButton } from './styles';

export const AdvertisementView = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showMoreImages, setShowMoreImages] = React.useState<boolean>(false);

  const { isLoading, data: adv } = useQuery(['advertisement', id], () => getAdvertisementById(id), {
    enabled: !!id,
  });

  const data: Advertisement = useMemo(() => {
    if (adv) {
      return adv;
    }
    return state?.usr?.advertisement;
  }, [adv, state?.usr?.advertisement]);

  if (!data && isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        mt={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <div>Advertisement not found</div>;
  }

  const {
    title,
    text,
    merchant,
    locations,
    currently_available,
    // start_date,
    end_date,
    is_home_visit_possible,
    images,
    rating,
    reviews_count,
  } = data;

  const contactButtonClick = () => {
    window.open(`https://telegram.me/${merchant?.tg_nickname}`, '_blank');
    sendAmplitudeEvent('Contact', { merchant: merchant?.tg_nickname });
  };

  const slides = images?.map(image => image.full_image_url)?.slice(0, SLIDE_COUNT) || [];

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      paddingTop={3}
      paddingBottom={2}
      minHeight="100vh"
      spacing={1}
      sx={{ bgcolor: 'background.main' }}>
      <Container>
        <Stack direction="row" spacing={1.5} alignItems="center" mb={1}>
          <Avatar alt="Profile Image" src={merchant?.profile_image_thumbnail} />
          <div style={{ minWidth: 0 }}>
            <Typography variant="body1" color="text.primary" mb={0.2} fontWeight={600}>
              {merchant?.name}
            </Typography>
            <Typography variant="body2" color="text.primary" mb={0.2}>
              {title}
            </Typography>
          </div>
        </Stack>

        <Stack direction="row" columnGap={1.5} alignItems="center" flexWrap="wrap">
          <IconWithDescription
            IconComponent={StarIcon}
            iconColor={rating ? '#FFB400' : undefined}
            description={
              rating
                ? `${Number(rating).toFixed(1)} (${reviews_count} ${getPluralReviews(reviews_count)})`
                : 'Нет отзывов'
            }
            fontSize={12}
          />

          {currently_available && (
            <IconWithDescription
              IconComponent={Home}
              description={'На острове' + (end_date ? ` до ${dayjs(end_date).format('DD.MM.YYYY')}` : '')}
              iconColor="success.main"
            />
          )}
          {locations?.length !== 0 && (
            <IconWithDescription
              IconComponent={PlaceIcon}
              description={locations?.map(l => l.name)?.join(', ')}
            />
          )}
          {is_home_visit_possible && <IconWithDescription IconComponent={ScooterIcon} description="Выезд" />}
          {merchant?.instagram_link && (
            <IconWithDescription IconComponent={InstagramIcon} description={merchant?.instagram_link} />
          )}
        </Stack>

        <Box marginY={2}>
          <Divider />
        </Box>

        <Typography variant="h4" fontSize={17} lineHeight="24px" fontWeight={600} mb={2}>
          Услуги
        </Typography>

        {text ? (
          <Typography variant="body1">
            <span style={{ paddingRight: '4px' }}>
              {showMoreInfo || text.length <= SERVICES_INFO_LENGTH
                ? text
                : `${text.substring(0, SERVICES_INFO_LENGTH)}...`}
            </span>
            <span>
              {text.length > SERVICES_INFO_LENGTH && !showMoreInfo && (
                <StyledButton variant="text" onClick={() => setShowMoreInfo(true)} color="primary">
                  Читать больше
                </StyledButton>
              )}
            </span>
          </Typography>
        ) : (
          <Typography variant="body1" whiteSpace="pre-line" color="text.secondary" mb={2}>
            Подробное описание услуг не заполнено
          </Typography>
        )}

        {slides.length !== 0 && (
          <ImageSlider slides={slides} onShowMoreClick={() => setShowMoreImages(true)} />
        )}

        <Box marginBottom={2} marginTop={3}>
          <Divider />
        </Box>

        <Reviews advertisement={data} />
      </Container>

      <FloatingButton onClick={contactButtonClick} title="Связаться" />

      <Modal open={showMoreImages} onClose={() => setShowMoreImages(false)} title="Фото">
        <ImageList variant="quilted" cols={2} gap={4}>
          {images.map(({ full_image_url }, index) => (
            <ImageListItem key={full_image_url} cols={index % 3 === 0 ? 2 : 1} rows={1}>
              <img src={full_image_url} srcSet={full_image_url} alt={full_image_url} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </Modal>
    </Stack>
  );
};
