import React, { ReactNode } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styles from './styles.module.css';
import { Props } from './types';

export const Slider = ({ children, options = { dragFree: true, containScroll: 'trimSnaps' } }: Props) => {
  const [emblaRef] = useEmblaCarousel(options);

  return (
    <div className={styles.slider}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

const Slide = ({ children }: { children: ReactNode }) => {
  return <div className={styles.slide}>{children}</div>;
};

Slider.Slide = Slide;
