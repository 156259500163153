import * as React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
import { CheckboxCheckedIcon, CheckboxIcon } from './styles';

export const Checkbox = (props: CheckboxProps) => {
  return (
    <MuiCheckbox
      sx={{ padding: 0 }}
      disableRipple
      color="default"
      checkedIcon={<CheckboxCheckedIcon />}
      icon={<CheckboxIcon />}
      {...props}
    />
  );
};
