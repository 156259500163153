import { Avatar, Box, Button, Input, Rating, Stack, TextField, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { WEB_APP } from '../../../common/telegram';
import { Modal } from '../../../common/components';
import React, { ChangeEvent, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { postReview } from '../../../services/search';
import { useParams } from 'react-router-dom';
import { NewReviewModalProps } from './types';

export const NewReviewModal = ({ open, onClose, rating, onChangeRating }: NewReviewModalProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  // const ratingRef = useRef<HTMLDivElement>(null);

  const [name, setName] = useState(WEB_APP?.initDataUnsafe?.user?.first_name ?? '');
  const [content, setContent] = useState('');
  const [selectedPhotos, setSelectedPhotos] = useState<File[]>([]);
  const [photoPreviews, setPhotoPreviews] = useState<string[]>([]);

  const { mutate: sendReview, isLoading: isSending } = useMutation({
    mutationFn: () => {
      return postReview(id, { rating: rating ?? 0, content, user_name: name });
    },
    onSuccess: async data => {
      await queryClient.invalidateQueries(['advertisement', id]); // TODO: попросить Тему возвращать объявление в ответ мб
      onClose();
    },
    onError: async (error: { status: number; message: string }) => {
      if (error.status === 400) {
        console.log(error.message);
      }
    },
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef?.current?.click();
  };

  // const handleFilesSelect = (event: ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   console.log(files);
  //   // onFilesSelect(files);
  // };

  const handleFilesSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setSelectedPhotos(files);

      // const formData = new FormData();
      // selectedPhotos.forEach(photo => {
      //   formData.append('photos', photo);
      // });
      //
      // console.log(formData);

      const previews: string[] = [];
      files.forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          if (e.target && e.target.result) {
            previews.push(e.target.result as string);
            if (previews.length === files.length) {
              setPhotoPreviews(previews);
            }
          }
        };
        reader.readAsDataURL(file);
      });
    }

    console.log(selectedPhotos);
    console.log(photoPreviews);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Отзывы"
      primaryAction={{ content: 'Отправить', onAction: sendReview, disabled: !name || !content }}>
      <Box paddingX={2} paddingY={3}>
        <Stack direction="row" spacing={1} alignItems="center" mb={'38px'}>
          <StarIcon fontSize="medium" />
          <Typography variant="h6" fontWeight={600}>
            Ваш отзыв
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="">
            <Avatar src={WEB_APP?.initDataUnsafe?.user?.photo_url} />
            <Input
              spellCheck={false}
              type="text"
              placeholder="Ваше имя"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            {/*<TextField spellCheck={false} value={'Anastasia'} size="small" />*/}
          </Stack>
          <Rating
            // name="simple-controlled"
            size="large"
            // ref={ratingRef}
            value={rating}
            onChange={(event, newValue) => {
              onChangeRating(newValue);
            }}
          />
          <TextField
            multiline
            spellCheck={false}
            placeholder="Расскажите о своем опыте"
            minRows={3}
            value={content}
            onChange={e => setContent(e.target.value)}
          />
          {/*<Button variant="outlined" color="inherit" fullWidth>*/}
          {/*  Добавить фото*/}
          {/*</Button>*/}
          {/*<input*/}
          {/*  id="photo-upload"*/}
          {/*  type="file"*/}
          {/*  style={{ display: 'none' }}*/}
          {/*  accept="image/*"*/}
          {/*  multiple*/}
          {/*  onChange={handleFilesSelect}*/}
          {/*/>*/}
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  color="inherit"*/}
          {/*  fullWidth*/}
          {/*  onClick={handleButtonClick}*/}
          {/*  component="label"*/}
          {/*  htmlFor="photo-upload">*/}
          {/*  Добавить фото*/}
          {/*</Button>*/}
          {/*<ul>*/}
          {/*  {photoPreviews.map((preview, index) => (*/}
          {/*    <li key={index}>*/}
          {/*      <img src={preview} alt={`Photo ${index}`} height={100} />*/}
          {/*    </li>*/}
          {/*  ))}*/}
          {/*</ul>*/}
          {/*<Input*/}
          {/*  id="photo-upload"*/}
          {/*  type="file"*/}
          {/*  inputProps={{ accept: 'image/*' }}*/}
          {/*  style={{ display: 'none' }}*/}
          {/*  onChange={() => {}}*/}
          {/*/>*/}
        </Stack>
      </Box>
    </Modal>
  );
};
