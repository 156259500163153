import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function InstagramIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M4.77932 2.03409C3.47571 2.21706 2.42596 3.15474 2.10578 4.42404C2.07376 4.54297 2.03945 4.73279 2.02573 4.84714C1.99142 5.13073 1.99142 10.5784 2.02573 10.862C2.18353 12.1954 3.13265 13.2794 4.42483 13.6042C4.54375 13.6362 4.73586 13.6705 4.84793 13.6842C5.13152 13.7185 10.5792 13.7185 10.8628 13.6842C12.0635 13.5424 13.0675 12.758 13.5043 11.6236C13.5752 11.4384 13.6576 11.0907 13.685 10.862C13.7193 10.5784 13.7193 5.13073 13.685 4.84714C13.5432 3.64645 12.7588 2.64244 11.6244 2.20562C11.4392 2.13472 11.0915 2.05239 10.8628 2.02495C10.5495 1.98607 5.05147 1.99521 4.77932 2.03409ZM11.5512 3.52524C11.8462 3.62358 12.0841 3.86143 12.1847 4.15875C12.2328 4.30054 12.235 4.57956 12.1893 4.7305C12.0018 5.35029 11.2631 5.61559 10.7256 5.25652C10.41 5.0484 10.243 4.67562 10.3002 4.31198C10.3597 3.94605 10.609 3.64645 10.9497 3.52981C11.1052 3.47721 11.4003 3.47264 11.5512 3.52524ZM8.35852 4.68705C9.03777 4.79912 9.63697 5.10558 10.1195 5.59043C10.7347 6.20335 11.0572 6.98323 11.0572 7.85459C11.0572 8.72595 10.7347 9.50583 10.1195 10.1187C9.50661 10.734 8.72673 11.0564 7.85537 11.0564C6.98401 11.0564 6.20414 10.734 5.59121 10.1187C4.976 9.50583 4.65353 8.72595 4.65353 7.85459C4.65353 6.98323 4.976 6.20335 5.59121 5.59043C6.06463 5.11473 6.66612 4.80369 7.32936 4.68705C7.56492 4.64817 8.12067 4.64588 8.35852 4.68705Z"
        fill="currentColor"
      />
      <path
        d="M11.1232 4.2068C10.9906 4.27769 10.9403 4.47209 11.0203 4.59559C11.1758 4.83344 11.5372 4.72824 11.5372 4.44693C11.5372 4.2411 11.3039 4.10845 11.1232 4.2068Z"
        fill="currentColor"
      />
      <path
        d="M7.41141 5.37543C6.21986 5.59041 5.33936 6.64244 5.33936 7.85457C5.33936 9.40746 6.75503 10.6013 8.28048 10.336C9.95687 10.0433 10.8671 8.21135 10.0895 6.69962C9.58637 5.72763 8.48402 5.18103 7.41141 5.37543Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
