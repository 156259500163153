import { css, Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-end',
  },
  '& .MuiPaper-root': {
    backgroundImage: 'none',
    boxShadow: 'none',
  },
  '& .MuiModal-backdrop': {
    backgroundColor: theme.palette.backdrop,
  },
}));

export const StyledModalHeader = styled('div')(({ theme }) =>
  css(`
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: 1100;
    top: 0;
    left: auto;
    right: 0;
    padding: 18px 16px 14px;
    border-bottom: 1px solid #E6E6E6;
  `)
);

export const StyledModalContent = styled('div')(({ theme }) =>
  css(`
    overflow: scroll;
    flex: 1 1 auto;
  `)
);

export const StyledCloseButtonContainer = styled('div')(({ theme }) =>
  css(`
    display: flex;
    position: absolute;
    top: 18px;
    left: ${theme.spacing(2)};
  `)
);

export const StyledPrimaryActionContainer = styled('div')(({ theme }) =>
  css(`
    display: flex;
    position: absolute;
    top: 15px;
    right: ${theme.spacing(2)};
  `)
);

export const StyledModalFooter = styled('div')(({ theme }) =>
  css(`
    display: flex;
    justify-content: center;
    position: sticky;
    z-index: 1100;
    bottom: 0;
    left: auto;
    right: 0;
    padding: ${theme.spacing(2)};
    border-top: 1px solid #E6E6E6;
  `)
);
