import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import { initAmplitude } from './utilities/amplitude';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');
initAmplitude();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
