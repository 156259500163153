import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styles from './styles.module.css';
import { Props } from './types';

export const ImageSlider = ({
  slides,
  options = { dragFree: true, containScroll: 'trimSnaps' },
  onShowMoreClick,
}: Props) => {
  const [emblaRef] = useEmblaCarousel(options);

  return (
    <div className={styles.slider}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {slides.map((src, index) => (
            <div className={styles.slide} key={index}>
              <img className={styles.slideImg} src={src} alt="Services" />
              {index === slides.length - 1 && (
                <div className={styles.slideOverlay} onClick={onShowMoreClick}>
                  <p className={styles.slideText}>Больше фото</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
