import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Modal } from '../../../common/components';
import React, { Fragment, useMemo } from 'react';
import { ViewReviewsModalProps } from './types';
import { useInfiniteQuery } from 'react-query';
import { getReviews } from '../../../services/search';
import { useParams } from 'react-router-dom';
import { getPluralReviews } from '../../../utilities';
import { ViewReviewCard } from './ViewReviewCard';

export const ViewReviewsModal = ({ open, onClose, rating, reviewsCount }: ViewReviewsModalProps) => {
  const { id } = useParams();
  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['reviews', id],
    ({ pageParam = 1 }) => getReviews(id, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.has_next_page) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );

  const flatData = useMemo(() => data?.pages.flatMap(page => page.reviews) ?? [], [data]);

  return (
    <Modal open={open} onClose={onClose} title="Отзывы">
      <Box paddingX={2} paddingY={3}>
        <Stack direction="row" spacing={1} alignItems="center" mb={'38px'}>
          <StarIcon fontSize="medium" />
          <Typography variant="h6" fontWeight={600}>
            {rating
              ? `${Number(rating).toFixed(1)} (${reviewsCount} ${getPluralReviews(reviewsCount)})`
              : 'Нет отзывов'}
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt={5}>
            <CircularProgress />
          </Box>
        ) : (
          <InfiniteScroll
            // style={{ overflow: 'hidden' }}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                mt={5}>
                <CircularProgress />
              </Box>
            }
            dataLength={flatData?.length ?? 0}>
            <Stack direction="column" spacing={2.5}>
              {flatData?.map((review, index) => {
                return (
                  <Fragment key={index}>
                    <ViewReviewCard review={review} />
                    {index !== flatData?.length - 1 && <Divider />}
                  </Fragment>
                );
              })}
            </Stack>
          </InfiniteScroll>
        )}
      </Box>
    </Modal>
  );
};
