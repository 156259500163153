import { Card, Chip, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  borderColor: theme.palette.divider,
  borderStyle: 'solid',
  borderWidth: '1px',
  boxShadow: 'none',
}));

interface StyledChipProps {
  status: 'active' | 'inactive' | 'new';
}

const getStatusColors = (
  status: 'active' | 'inactive' | 'new',
  theme: Theme
): { backgroundColor: string; color: string | undefined } => {
  switch (status) {
    case 'active':
      return {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      };
    case 'inactive':
      return {
        backgroundColor: theme.palette.default.main,
        color: theme.palette.default.contrastText,
      };
    case 'new':
      return {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
      };
    default:
      return {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
      };
  }
};

export const StyledChip = styled(Chip)<StyledChipProps>(({ status, theme }) => ({
  ...getStatusColors(status, theme),
  fontWeight: 500,
}));
