import { useInfiniteQuery } from 'react-query';
import React, { useMemo } from 'react';
import { Box, CircularProgress, Container, Stack, Typography, useTheme } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAdvertisements } from '../../services/search';
import { SearchBar } from './SearchBar';
import { DEFAULT_CATEGORY } from './FiltersModal/constants';
import { AdvertisementCard } from './AdvertisementCard';
import { FiltersModal } from './FiltersModal';
import useDebounce from '../../hooks/useDebounce';
import { useSearchParamsState } from '../../hooks';

export const AdvertisementList = () => {
  const [searchParams, setSearchParams] = useSearchParamsState();
  const [openFilters, setOpenFilters] = React.useState(false);

  const locations = useMemo(() => searchParams.getAll('locations'), [searchParams]);
  const categories = searchParams.get('categories');
  const query = searchParams.get('query');

  const isFiltersApplied = useMemo(() => {
    return !!locations?.length || !!categories;
  }, [categories, locations?.length]);

  const debouncedQuery = useDebounce(query, 600);
  const theme = useTheme();

  const params = useMemo(
    () => ({ locations, categories, q: debouncedQuery }),
    [categories, debouncedQuery, locations]
  );

  const { isError, isLoading, data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['advs', categories, locations, debouncedQuery],
    ({ pageParam = 1 }) => getAdvertisements(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.has_next_page) {
          return pages.length + 1;
        }
        return undefined;
      },
    }
  );

  const handleQueryChange = (value: string | null) => {
    setSearchParams({ query: value });
  };

  const handleCategoriesChange = (value: string | null) => {
    setSearchParams({ categories: value });
  };

  const handleLocationsChange = (value: string[] | null) => {
    setSearchParams({ locations: value });
  };

  const handleFiltersOpen = () => {
    setOpenFilters(true);
  };

  const handleFiltersClose = () => {
    setOpenFilters(false);
  };

  const flatData = useMemo(() => data?.pages.flatMap(page => page.advs) ?? [], [data]);

  if (isError) {
    return <div>'Something went wrong'</div>;
  }

  return (
    <>
      <Container sx={{ minHeight: '100vh', bgcolor: 'background.default', padding: 0 }}>
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme.palette.background.default,
            width: '100%',
            padding: '16px',
            maxWidth: 720,
            margin: '0 auto',
          }}>
          <SearchBar
            value={query ?? ''}
            onChange={handleQueryChange}
            onFiltersClick={handleFiltersOpen}
            isFiltersApplied={isFiltersApplied}
          />
        </div>
        {isLoading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} mt={5}>
            <CircularProgress />
          </Box>
        ) : (
          <InfiniteScroll
            style={{ overflow: 'hidden', padding: '0 16px 16px', maxWidth: 720, margin: '0 auto' }}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <Box
                sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                mt={5}>
                <CircularProgress />
              </Box>
            }
            dataLength={flatData?.length ?? 0}>
            <Stack
              spacing={2}
              sx={{ width: '100%', maxWidth: 720, bgcolor: 'background.default', margin: '0 auto' }}>
              {flatData?.length ? (
                flatData?.map(item => <AdvertisementCard key={item.id} advertisement={item} />)
              ) : (
                <Box px={2} py={1}>
                  <Typography fontSize={18} pb={1}>
                    Ничего не найдено
                  </Typography>
                  <Typography fontSize={14}>Попробуйте изменить запрос или фильтры</Typography>
                </Box>
              )}
            </Stack>
          </InfiniteScroll>
        )}
      </Container>
      <FiltersModal
        open={openFilters}
        onClose={handleFiltersClose}
        locations={locations ?? []}
        category={categories ? categories : DEFAULT_CATEGORY.name}
        onLocationChange={handleLocationsChange}
        onCategoryChange={handleCategoriesChange}
        count={data?.pages?.[0]?.total_advs ?? 0}
        isDataLoading={isLoading}
      />
    </>
  );
};
