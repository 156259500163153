import { styled } from '@mui/material/styles';

export const CheckboxIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  boxSizing: 'border-box',
  width: 24,
  height: 24,
  border: `1px solid`,
  'input:checked ~ &': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const CheckboxCheckedIcon = styled(CheckboxIcon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    display: 'block',
    width: 13,
    height: 10,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 10'%3E%3Cpath" +
      " fill-rule='evenodd' d='M5.80781 9.02288C5.42727 9.48678 4.72671 9.51346 4.31199 9.07984L0.360056 4.94789C0.158106 4.73674 0.156448 4.40455 0.35628 4.1914C0.573619 3.95957 0.94161 3.95957 1.15895 4.1914L4.99282 8.28086L11.4136 0.453618C11.6152 0.207921 11.9823 0.183494 12.2146 0.400326C12.4269 0.598406 12.4487 0.927271 12.2646 1.15172L5.80781 9.02288Z' fill='white'/%3E%3C/svg%3E\")",
    content: '""',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));
