import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': '*',
  },
});

// Error handling
// api.interceptors.response.use(
//   (response: AxiosResponse<RequestResponse<unknown>>) => {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion
//     if (!(response.headers['content-type'] as string).includes('application/json')) {
//       return response;
//     }
//     if (response.data.success) {
//       if (
//         response.data.messages?.some(({ type }) => ['error', 'validation'].includes(type))
//       ) {
//         return Promise.reject(response.data.messages);
//       }
//     } else {
//       return Promise.reject(response.data.error);
//     }
//     return response;
//   },
// );

export default api;
