import { Badge, Divider, IconButton, InputAdornment, InputBase } from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Search } from '@mui/icons-material';
import { FilterIcon } from '../../../common/components';
import { StyledPaper } from './styles';

interface Props {
  value: string;
  onChange: (value: string | null) => void;
  onFiltersClick: () => void;
  isFiltersApplied: boolean;
}

export const SearchBar = ({ value, onChange, onFiltersClick, isFiltersApplied }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    onChange(value ? value : null);
  };

  const handleClear = () => {
    onChange(null);
    inputRef?.current?.focus();
  };

  return (
    <StyledPaper elevation={0} component="form">
      <InputBase
        sx={{ flex: 1 }}
        startAdornment={
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          value && (
            <IconButton aria-label="clear input" onClick={handleClear}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={handleChange}
        placeholder="Искать услугу"
        inputRef={inputRef}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="button" aria-label="filters" onClick={onFiltersClick}>
        <Badge sx={{ right: '-2px' }} color="primary" variant="dot" invisible={!isFiltersApplied}>
          <FilterIcon sx={{ fontSize: '16px' }} />
        </Badge>
      </IconButton>
    </StyledPaper>
  );
};
