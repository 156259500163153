import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const StyledClampText = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledSlideWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: '16px',
  border: '1px solid #E6E6E6',
  borderRadius: '0px 8px 8px 8px',
  // marginBottom: '16px',
  height: '132px',
  // width: '335px',
  // maxWidth: '100%',
}));

export const StyledSlideBottomLine = styled('div')(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));
