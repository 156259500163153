import { useEffect } from 'react';
import { BackButtonProps } from './types';
import { WEB_APP } from '../constants';

export const BackButton = ({ onClick }: BackButtonProps) => {
  useEffect(() => {
    WEB_APP?.BackButton?.show();
    return () => {
      WEB_APP?.BackButton?.hide();
    };
  }, []);

  useEffect(() => {
    if (!onClick) {
      return;
    }

    WEB_APP?.BackButton?.onClick(onClick);
    return () => {
      WEB_APP?.BackButton?.offClick(onClick);
    };
  }, [onClick]);

  return null;
};
