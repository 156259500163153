import { createTheme, SimplePaletteColorOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    backdrop: string;
    default: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    default: SimplePaletteColorOptions;
    backdrop: string;
  }

  interface TypeBackground {
    main: string;
    searchBar: string;
  }
}

const commonConfig = {
  typography: {
    fontFamily: ['Commissioner', 'Roboto', 'Arial', 'sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
};

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#BE3455',
    },
    text: {
      primary: '#212124',
      secondary: '#7F7F7F',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      main: '#FFFFFF',
      searchBar: '#F5F5F5',
    },
    success: {
      main: '#26BB98',
      contrastText: '#212124',
    },
    info: {
      main: '#633075',
      contrastText: '#FFF',
    },
    default: {
      main: '#858282',
      contrastText: '#FFF',
    },
    divider: '#E6E6E6',
    backdrop: 'rgba(33, 33, 36, 0.6)',
  },
  ...commonConfig,
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#BE3455',
    },
    text: {
      primary: '#FFF',
      secondary: '#C0C0C0',
    },
    background: {
      paper: '#2C2C2C',
      default: '#111111',
      main: '#212124',
      searchBar: '#2C2C2C',
    },
    success: {
      main: '#0CF0BB',
      contrastText: '#212124',
    },
    info: {
      main: '#9152A7',
      contrastText: '#FFF',
    },
    default: {
      main: '#858282',
      contrastText: '#FFF',
    },
    divider: '#444444',
    backdrop: 'rgba(196, 196, 196, 0.6)',
  },
  ...commonConfig,
});

export { lightTheme, darkTheme };
