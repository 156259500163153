import { useSearchParams } from 'react-router-dom';

export const useSearchParamsState = (): [
  searchParams: URLSearchParams,
  setSearchParamsState: (
    newValues: { [key: string]: string | string[] | null },
    options?: { replace?: boolean }
  ) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearchParamsState = (
    newValues: { [key: string]: string | string[] | null },
    options?: { replace?: boolean }
  ) => {
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);

      for (const [key, value] of Object.entries(newValues)) {
        if (value === null) {
          prev.delete(key);
          newParams.delete(key);
        } else if (Array.isArray(value)) {
          newParams.delete(key);
          value.forEach(item => {
            newParams.append(key, item);
          });
        } else {
          newParams.set(key, value);
        }
      }

      return newParams;
    }, options);
  };

  return [searchParams, setSearchParamsState];
};
