import React, { useMemo } from 'react';
import { Avatar, Box, CardActionArea, CardContent, Divider, Stack, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { ScooterIcon } from '../../../common/components';
import { AdvertisementCardProps } from './types';
import { sendAmplitudeEvent } from '../../../utilities/amplitude';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { IconWithDescription } from '../../../common/components';
import { StyledCard, StyledChip } from './styles';
import dayjs from 'dayjs';
import StarIcon from '@mui/icons-material/Star';

export const AdvertisementCard = ({ advertisement }: AdvertisementCardProps) => {
  const navigate = useNavigate();

  const {
    id,
    title,
    merchant,
    locations,
    currently_available,
    start_date,
    is_home_visit_possible,
    rating,
    reviews_count,
  } = advertisement;

  const startMonth = useMemo(() => {
    const month = dayjs(start_date).format('MMMM');
    return month.charAt(month.length - 1) === 'ь' || month.charAt(month.length - 1) === 'й'
      ? 'В ' + month.slice(0, -1) + 'е'
      : 'В ' + month + 'е';
  }, [start_date]);

  const handleClick = () => {
    navigate(`${id}`, { state: advertisement });
    sendAmplitudeEvent('Card click', { merchant: merchant?.tg_nickname });
  };

  useMount(() => {
    sendAmplitudeEvent('Show merchant', { merchant: merchant?.tg_nickname });
  });

  return (
    <StyledCard onClick={handleClick}>
      <CardActionArea>
        <CardContent sx={{ padding: '12px' }}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Avatar alt="Profile Image" src={merchant?.profile_image_thumbnail} />
            <div style={{ minWidth: 0 }}>
              <Typography variant="body1" color="text.primary" mb={0.2} fontWeight={600}>
                {merchant?.name}
              </Typography>
              <Typography variant="body2" color="text.primary" mb={0.2} noWrap>
                {title}
              </Typography>
            </div>
          </Stack>
          <Box marginY={1}>
            <Divider />
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center">
              <IconWithDescription
                IconComponent={StarIcon}
                iconColor={rating ? '#FFB400' : undefined}
                description={rating ? `${Number(rating).toFixed(1)} (${reviews_count})` : 'Нет отзывов'}
                fontSize={12}
              />
              {locations?.length !== 0 && (
                <IconWithDescription
                  IconComponent={PlaceIcon}
                  description={locations?.map(l => l.name)?.[0]}
                  fontSize={12}
                />
              )}
              {is_home_visit_possible && <IconWithDescription IconComponent={ScooterIcon} description="" />}
            </Stack>
            {currently_available && <StyledChip size="small" label="На острове" status="active" />}
            {dayjs(start_date).isAfter(dayjs()) && (
              <StyledChip size="small" label={startMonth} status="new" />
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};
