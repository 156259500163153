import React, { useEffect } from 'react';
import './App.css';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { Advertisements } from '../pages/Advertisements';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useMount } from 'react-use';
import { sendAmplitudeEvent } from '../utilities/amplitude';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { Advertisement } from '../pages/Advertisement';
import { lightTheme, darkTheme } from '../common/theme';
import { IS_WEB_APP, WEB_APP } from '../common/telegram';

const queryDefaultConfig = new QueryClient({
  queryCache: new QueryCache(),
  mutationCache: new MutationCache(),
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  const [darkMode] = React.useState<boolean>(
    (IS_WEB_APP && WEB_APP?.colorScheme === 'dark') ||
      (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  );

  useMount(() => {
    sendAmplitudeEvent('App start');
  });

  useEffect(() => {
    const WebApp = typeof window !== 'undefined' ? window.Telegram.WebApp : null;
    WebApp?.expand();
  }, []);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryDefaultConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/advertisements" />} />
            <Route path="/advertisements" element={<Advertisements />} />
            <Route path="/advertisements/:id" element={<Advertisement />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
