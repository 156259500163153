import React, { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { StyledFab } from './styles';
import { Props } from './types';
import { MainButton, IS_WEB_APP } from '../../telegram';

export const FloatingButton = ({ title, onClick }: Props) => {
  const [isFloating, setIsFloating] = useState(true);
  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsFloating(false);
          } else {
            setIsFloating(true);
          }
        });
      },
      { threshold: 1 }
    );

    const target = targetRef.current;

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return IS_WEB_APP ? (
    <MainButton onClick={onClick} text={title} />
  ) : (
    <div>
      <div ref={targetRef} style={{ height: 1 }} />
      {isFloating ? (
        <StyledFab variant="extended" size="medium" color="primary" onClick={onClick}>
          {title}
        </StyledFab>
      ) : (
        <Box margin={2}>
          <Button variant="contained" color="primary" fullWidth onClick={onClick}>
            {title}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default FloatingButton;
